import React, { useState } from 'react';
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { copyWishlist, Wishlist, WishlistHeader } from './Interfaces';
import { save_wishlist } from './MyWisher'


const EditWishlistHeader = (props: { wishlist: Wishlist; onChange: (wishlistHeader: WishlistHeader) => void; }) => {

    const [editMode, setEditMode] = useState(false);
    const [name, setName] = useState("");
    const [text, setText] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const onEditToggle = () => {
        setName(props.wishlist.name);
        setText(props.wishlist.text);
        setEditMode(true);
    }
    const onEditCancel = () => {
        setEditMode(false);
    }
    const onEditSave = () => {
        const newWishlist = copyWishlist(props.wishlist);        
        newWishlist.name = name;
        newWishlist.text = text;

        setError("");
        setIsLoading(true);
        save_wishlist(newWishlist).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            setError("Failed to save.");
        }).then(data => {
            setEditMode(false);
            props.onChange(newWishlist);
        }).catch(error => {
            setError("Unexpected error");
        }).finally(() => {
            setIsLoading(false);
        });
    }

    if (props.wishlist) {
        return (
            <>
                <Row className="justify-content-md-center mb-4 mt-4">
                    <Col sm={12} md={8} lg={8}>
                        {editMode ?
                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Text className="text-muted">
                                        Title for wishlist
                                    </Form.Text>
                                    <Form.Control type="text" placeholder="" value={name} onChange={e => setName(e.target.value)} />
                                    <Form.Text className="text-muted">
                                        Description
                                    </Form.Text>
                                    <Form.Control as="textarea" rows={3} value={text} onChange={e => setText(e.target.value)} />
                                </Form.Group>
                                <div>
                                    <div style={{ float: "left" }}>
                                        <p style={{ color: "red" }}>{error}</p>
                                    </div>
                                    <div style={{ float: "right" }}>
                                        <Button variant="primary" onClick={onEditSave} className="header-edit-save" style={{ float: "right" }}>
                                            {isLoading ? <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            /> : <></>
                                            }
                                            SAVE
                                        </Button>
                                        <Button variant="secondary" onClick={onEditCancel} className="header-edit-cancel" style={{ float: "right", marginRight: "5px" }}>
                                            CANCEL
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                            :
                            <>
                                <div style={{ float: "left" }}>
                                    <h1 className="wishlist-header-title" style={{letterSpacing: "0.1em", color: "black"}}>{props.wishlist.name}</h1>
                                    <p>{props.wishlist.text || "(You have not provided a description text.)"}</p>
                                </div>
                                <div style={{ float: "right" }}>
                                    <Button onClick={onEditToggle} className="edit-wish-list-button header-edit-toggle">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-wrench-adjustable" viewBox="0 0 16 16">
                                            <path d="M16 4.5a4.492 4.492 0 0 1-1.703 3.526L13 5l2.959-1.11c.027.2.041.403.041.61Z"></path>
                                            <path d="M11.5 9c.653 0 1.273-.139 1.833-.39L12 5.5 11 3l3.826-1.53A4.5 4.5 0 0 0 7.29 6.092l-6.116 5.096a2.583 2.583 0 1 0 3.638 3.638L9.908 8.71A4.49 4.49 0 0 0 11.5 9Zm-1.292-4.361-.596.893.809-.27a.25.25 0 0 1 .287.377l-.596.893.809-.27.158.475-1.5.5a.25.25 0 0 1-.287-.376l.596-.893-.809.27a.25.25 0 0 1-.287-.377l.596-.893-.809.27-.158-.475 1.5-.5a.25.25 0 0 1 .287.376ZM3 14a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"></path>
                                        </svg>
                                    </Button>                                    
                                </div>
                            </>
                        }
                    </Col>
                </Row>
            </>
        );
    } else {
        return (
            <Row className="justify-content-md-center  mb-4 mt-4">
                <Col sm={12} md={8} lg={8}>
                    <div className="m-2">
                        No wishlist was specified
                    </div>
                </Col>
            </Row>
        );
    }
}

export default EditWishlistHeader;