import { Wishlist } from "./Interfaces";

const baseUrl = process.env.REACT_APP_API_URL

export function datetime_to_date(datetime_str : string) {
    var t = datetime_str.split(/[- :]/).map(v => Number(v));
    var d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));
    return d;
}

export function date_to_datetime(date : Date) : string {
    const datetime_str = date.toISOString().slice(0, 19).replace("T", " ");
    return datetime_str;
}

export function email_user_login(email : string) {
    const data = {
        userId: email,
    }
    return fetch(baseUrl+"login/links", { 
        method: "POST", 
        headers: {},
        credentials: 'omit',
        body: JSON.stringify(data),
    });
}

export function user_login(email: string, expiration: string, signatureHex: string) {
    const data = {
        userId: email,
        expiration: expiration,
    }
    return fetch(`${baseUrl}/login/links/${signatureHex}`, { 
        method: "POST", 
        headers: {},
        credentials: 'omit',
        body: JSON.stringify(data),
    });
}

export function set_token(token: string) {
    localStorage.setItem("token", token);
}

export function clean_token() {
    localStorage.removeItem("token");
}

export function get_token() : string | null {
    const token = localStorage.getItem("token");
    if (token === null) {
        return null;
    }
    if (is_token_expired(token)) {
        clean_token();
        console.log("Token has expired");
        return null;
    }
    return token;
}

export function has_token() : boolean {
    const token = get_token();
    return token !== null;
}

export function is_user_logged_in() : boolean {
    return has_token();
}

function parse_jwt(token : string) : any {
    const jwt_payload = token.split('.')[1];
    return JSON.parse(atob(jwt_payload));
}

function is_token_expired(token : string) : boolean {
    const expiry = parse_jwt(token).exp;
    return (Math.floor((new Date()).getTime() / 1000)) >= expiry;
}

export function google_login(googleToken: any) : Promise<any> {
    const data = {
        token: googleToken        
    };
    console.log(data);
    return fetch(`${baseUrl}login/google`, { 
        method: "POST", 
        credentials: 'omit',
        body: JSON.stringify(data), 
    }).then(response => {
        if (response.status === 200) {
            console.log("Google login successfull");
            return response.json();
        } else {
            console.error("Google login failed", response);
            throw new Error(`Google login failed with statuscode:  ${response.status} and message: `);
        }
    }).then(body => {
        set_token(body)
        return body;
    });
}

export function account() {
    const token = get_token()
    if (token === null) {
        throw new Error("User is not logged in")
    }

    return fetch(`${baseUrl}account`, { 
        method: "GET", 
        headers: {
            'Authorization': 'Bearer '+token
        },
        credentials: 'omit' 
    });
}

export function get_wishlist(wishlist_id : string) {
    return fetch(`${baseUrl}wishlists/${wishlist_id}`, { 
        method: "GET", 
        headers: {
            'Authorization': 'Bearer '+get_token()
        },
        credentials: 'omit' 
    });
}

export function get_public_wishlist(wishlist_id : string) {
    return fetch(`${baseUrl}wishlists/${wishlist_id}/public`, { 
        method: "GET", 
        headers: {
            'Authorization': 'Bearer '+get_token()
        },
        credentials: 'omit' 
    });
}

export function get_public_wishlist_by_url(shorthand : string) {
    return fetch(`${baseUrl}shorthands/${shorthand}`, { 
        method: "GET", 
        credentials: 'omit'
    }).then(response => {
        if (response.status === 200) {
            return response.json();
        }
        throw Error("No wishlist found for shorthand")
    }).then(body => {
        return get_public_wishlist(body.wishlistId);
    });
}

export function add_user_favorite(wishlist_id : string, name: string) {
    const data = {
        wishlistId: wishlist_id,
        name: name,
    };
    return fetch(`${baseUrl}account/favorites`, { 
        method: "POST", 
        headers: {
            'Authorization': 'Bearer '+get_token()
        },
        credentials: 'omit',
        body: JSON.stringify(data),
    });
}

export function remove_user_favorite(wishlist_id : string) {
    return fetch(`${baseUrl}account/favorites/${wishlist_id}`, { 
        method: "DELETE", 
        headers: {
            'Authorization': 'Bearer '+get_token()
        },
        credentials: 'omit'
    });
}


export function delete_wishlist(wishlist_id: string) {
    return fetch(`${baseUrl}wishlists/${wishlist_id}`, { 
        method: "DELETE", 
        headers: {
            'Authorization': 'Bearer '+get_token()
        },
        credentials: 'omit'
    });
}

export function new_wishlist(wishlist: Wishlist) {
    return fetch(`${baseUrl}wishlists`, { 
        method: "POST", 
        headers: {
            'Authorization': 'Bearer '+get_token()
        },
        credentials: 'omit' ,
        body: JSON.stringify(wishlist)
    });
}

export function save_wishlist(wishlist: Wishlist) {
    return fetch(`${baseUrl}wishlists/${wishlist.wishlistId}`, { 
        method: "PUT", 
        headers: {
            'Authorization': 'Bearer '+get_token()
        },
        credentials: 'omit' ,
        body: JSON.stringify(wishlist)
    });
}

export function get_web_page_meta_tags(link: string) {
    return fetch(`${baseUrl}webpage/crawls`, { 
        method: "POST", 
        headers: {
            'Authorization': 'Bearer '+get_token()
        },
        credentials: 'omit' ,
        body: JSON.stringify({"url": link})
    });
}

export function reserve_item(wishlist_id: string, item_id: string | null, comment: string | null) {
    return fetch(`${baseUrl}wishlists/${wishlist_id}/reservations`, { 
        method: "POST", 
        headers: {
            'Authorization': 'Bearer '+get_token()
        },
        credentials: 'omit' ,
        body: JSON.stringify({"itemId": item_id})
    });
}

export function update_wishlist_url(id: string, url: string) {
    return fetch(`${baseUrl}shorthands/${url}`, { 
        method: "PUT", 
        headers: {
            'Authorization': 'Bearer '+get_token()
        },
        credentials: 'omit' ,
        body: JSON.stringify({"wishlistId": id})
    });
}

export function check_wishlist_by_url(url: string) {
    return fetch(`${baseUrl}shorthands/${url}`, { 
        method: "GET", 
        headers: {},
        credentials: 'omit',
        body: null
    });
}

export function save_external_image(url: string) {
    return fetch(`${baseUrl}images`, { 
        method: "POST", 
        headers: {
            'Authorization': 'Bearer '+get_token()
        },
        credentials: 'omit' ,
        body: JSON.stringify({"externalUrl": url})
    });
}

export function upload_file(file: File, filename: string) : Promise<string> {
    console.log("upload_file", filename)
    return fetch(`${baseUrl}s3-uploads`, { 
        method: "POST", 
        headers: {
            'Authorization': 'Bearer '+get_token()
        },
        credentials: 'omit'
    }).then(response => {
        if (response.status === 201) {
            return response.json()
        } 
        throw new Error("Unable to request presigned URL");
    }).then(o => {
        const url = o.url
        const public_url = o.public_url
        let formData = new FormData()
        const fields = ['key', 'x-amz-algorithm', 'x-amz-credential', 'x-amz-date', 'policy', 'x-amz-signature', 'x-amz-security-token']
        fields.forEach(e => {
            formData.append(e, o.fields[e])
        })
        formData.append('file', file, filename)
        fetch(url, {
            method: 'POST',
            body: formData,
        })
        return public_url
    });
}
