import React, { useState, useEffect, useRef, ChangeEventHandler, ChangeEvent } from 'react';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import Navigation from './Navigation';
import WishlistHeader from './WishlistHeader';
import { email_user_login, account, is_user_logged_in, delete_wishlist, new_wishlist } from './MyWisher'
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { mapFavorite } from './Util'
import { google_login } from './MyWisher'
import { WishlistHead, ApiFavorite, Favorite } from './Interfaces';

const EmailSentComponent = () => {
    return <Row id='emailSentDiv' className="justify-content-center gy-5 mb-4">
        <Col sm={12} md={8} lg={8}>
            <div className="m-2">
                <div className="fs-2 mb-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-send-check" viewBox="0 0 16 16">
                        <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855a.75.75 0 0 0-.124 1.329l4.995 3.178 1.531 2.406a.5.5 0 0 0 .844-.536L6.637 10.07l7.494-7.494-1.895 4.738a.5.5 0 1 0 .928.372l2.8-7Zm-2.54 1.183L5.93 9.363 1.591 6.602l11.833-4.733Z" />
                        <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                    </svg>{' '}
                    Email has been sent!
                </div>
                <p> Email is on its way to your mailbox! Maybe it went to your <u style={{ whiteSpace: "nowrap" }}>spam-folder</u>? </p>
            </div>
        </Col>
    </Row>;
}

const Home = () => {

    const [isEmailSent, setEmailIsSent] = useState<boolean>(false);
    const [isSendingEmail, setIsSendingEmail] = useState<boolean>(false);
    const [isGoogleLogginIn, setIsGoogleLogginIn] = useState<boolean>(false);
    const [isDeleteLoading, setIsDeleteloading] = useState<boolean>(false);
    const [deleteError, setDeleteError] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");
    const [isNewLoading, setIsNewloading] = useState<boolean>(false);
    const [newError, setNewError] = useState<string>("");
    const [emailValue, setEmailValue] = useState<string>("");
    const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
    const [wishlists, setWishlists] = useState<WishlistHead[] | null>(null);
    const [favorites, setFavorites] = useState<Favorite[] | null>(null);
    const [googleLoginError, setGoogleLoginError] = useState<string>("");


    const handleEmailChange : ChangeEventHandler<HTMLInputElement> = (e : ChangeEvent<HTMLInputElement>) => {
        var emailValue = e.target.value;
        setEmailValue(emailValue);
        var index = emailValue.indexOf("@");
        setIsEmailValid(index >= 1 && index < emailValue.length - 1);
    }

    const handleDeletion = (wishlistId: string) => {
        if (wishlists === null) {
            console.warn("Cannot handle deletion as wishlists are not loaded."); 
            return;
        }
        const index = wishlists.findIndex((x) => x.wishlistId === wishlistId);
        setIsDeleteloading(true);
        setDeleteError("");
        delete_wishlist(wishlistId).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            setDeleteError("Failed to delete");
            throw new Error("Response was not ok");
        }).then(_ => {
            setWishlists(wishlists => {
                if (wishlists === null) {
                    return null;
                }
                const newWishlists = [...wishlists];
                newWishlists.splice(index, 1);
                return newWishlists;
            });        
        }).catch(error => {
            console.error(error);
            setDeleteError("Unexpected error");
        }).finally(() => {
            setIsDeleteloading(false);
        });
    }

    const handleNew = () => {
        setIsNewloading(true);
        setNewError("");
        const wishlist = {
            wishlistId: "", // will be udpated
            ownerId: "", // will be updated
            created: "", // will be updated
            updated: "", // will be updated
            name : "A New Wishlist",
            text: "",
            url: "",
            items: [],
            collaborators: [],
        }        
        new_wishlist(wishlist).then(response => {
            if (response.status === 200 || response.status === 201) {
                return response.json();
            } else {
                console.error("Response was not ok");
                setNewError("Failed to create wishlist");
            }
        }).then(data => {
            console.log("new wishlist added", data);
            setWishlists(wishlists => {
                if (wishlists === null) {
                    return null;
                }
                const newWishlists = [...wishlists, data];
                return newWishlists;
            });
        }).catch(error => {
            console.error(error);
            setNewError("Unexpected error");
        }).finally(() => {
            setIsNewloading(false);
        });
    }

    const trySendingEmail = () => {
        setIsSendingEmail(true);
        setEmailError("");
        email_user_login(emailValue).then(response => {
            if (response.status === 200 || response.status === 201) {
                return response.json();
            } 
            setEmailError("Error sending request");
            return null;
        }).then(data => {
            if (data !== null) {
                setEmailIsSent(true);
            }
        }).catch(error => {
            setEmailError("Unexpected error");
        }).finally(() => {
            setIsSendingEmail(false);
        });
    }

    const handleGoogleLogin = (credentialResponse: any) => {
        setIsGoogleLogginIn(true);
        setGoogleLoginError("");
        const googleToken = credentialResponse.credential;

        google_login(googleToken).then(data => {
            loadWishlists();
        }).catch(error => {
            console.error(error);
            setGoogleLoginError("Unexpected error");
        }).finally(() => {
            setIsGoogleLogginIn(false);
        });
    }

    const handleGoogleError = () => {
        console.error("failed to login with google");
        setGoogleLoginError("Failed to login..");
    }

    const calledWishlsitOnce = useRef(false);

    const loadWishlists = () => {
        if (!is_user_logged_in()) {
            return;
        }

        account().then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                console.error("Response status code is ", response.status);
            }
        }).then(data => {
            setWishlists(data.wishlists.owner);
            const l : ApiFavorite[] = data.favorites || [];
            setFavorites(mapFavorite(l));
        }).catch(error => {
            console.error(error);
        }).finally(() => {            
        });
    }

    useEffect(() => {
        if (calledWishlsitOnce.current) {
            return;
        }
        calledWishlsitOnce.current = true;
        
        loadWishlists();        
    });

    return (
        <Container>
            <Navigation favorites={favorites || []}/>
            <div className="pt-md-4">
                <Row className="row justify-content-md-center">
                    <Col sm={10} md={8} lg={8}>
                        <Row className="justify-content-end mb-4">
                            <Col sm={12} md={8} lg={8}>
                                <div className="m-2">
                                    <div className="">
                                        <h1 className="card-title">GET STARTED ON YOUR OWN ONLINE WISHLIST</h1>
                                        <p>My-Wisher is an easy way to manage and share your wishlist with friends and family for birthdays, Christmas and other events</p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} md={12} lg={1} className="d-none  d-lg-block" />
                            <Col sm={12} md={4} lg={3} className="d-none d-md-block d-lg-block">
                                <div style={{
                                    backgroundImage: "url(/pic/logo_200.png)",
                                    /*backgroundSizeCover: "cover",   is this needed??  */
                                    backgroundPosition: "50%",
                                    backgroundRepeat: "no-repeat",
                                    aspectRatio: 1 / 1,
                                }} >
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {
                    wishlists ? <></> :
                        (isEmailSent ?
                            EmailSentComponent()
                            :
                            <Row id='emailSendDiv' className="justify-content-center gy-5 mb-4">
                                <Col sm={12} md={8} lg={8}>
                                    <div className="m-2">
                                        <div className="mb-3">
                                            <input id='sendEmailLoginInput' type="email" className="form-control" placeholder="name@example.com" 
                                            value={emailValue} onChange={handleEmailChange} style={{ color: isEmailValid ? "" : "red" }} 
                                            disabled={isGoogleLogginIn || isSendingEmail}
                                            />
                                        </div>
                                        <div className="text-end">
                                            <p>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
                                                    <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"></path>
                                                </svg>
                                                Get a login-link to get access to your wishlists, or get started as a <u>new users</u>
                                            </p>
                                            <Button id='sendEmailLoginButton' type="button" className="btn btn-primary" onClick={trySendingEmail} disabled={!isEmailValid}>
                                                LOGIN-LINK
                                            </Button>{' '}
                                            {(isSendingEmail || isGoogleLogginIn) ?
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                /> : <></>
                                            }
                                            <p style={{ color: "red", fontStyle: "italic" }}>{emailError}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={12} md={8} lg={8}>
                                    <div className="m-2">
                                        <GoogleOAuthProvider clientId="332870345533-kd2rh9pbu4u1mea9ngko4b0guj8970ih.apps.googleusercontent.com">
                                            <GoogleLogin
                                                onSuccess={handleGoogleLogin}
                                                onError={handleGoogleError}
                                                useOneTap
                                            />
                                        </GoogleOAuthProvider>
                                        <p style={{color: "red"}}>{googleLoginError}</p>
                                    </div>
                                </Col>
                            </Row>
                        )
                }

                {
                    wishlists ?
                        <Row className="justify-content-center gy-5 mb-4">
                            <Col sm={12} md={8} lg={8}>
                                <div className="m-2">
                                    <div className="fs-1 mb-3 heading">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-balloon-heart" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="m8 2.42-.717-.737c-1.13-1.161-3.243-.777-4.01.72-.35.685-.451 1.707.236 3.062C4.16 6.753 5.52 8.32 8 10.042c2.479-1.723 3.839-3.29 4.491-4.577.687-1.355.587-2.377.236-3.061-.767-1.498-2.88-1.882-4.01-.721L8 2.42Zm-.49 8.5c-10.78-7.44-3-13.155.359-10.063.045.041.089.084.132.129.043-.045.087-.088.132-.129 3.36-3.092 11.137 2.624.357 10.063l.235.468a.25.25 0 1 1-.448.224l-.008-.017c.008.11.02.202.037.29.054.27.161.488.419 1.003.288.578.235 1.15.076 1.629-.157.469-.422.867-.588 1.115l-.004.007a.25.25 0 1 1-.416-.278c.168-.252.4-.6.533-1.003.133-.396.163-.824-.049-1.246l-.013-.028c-.24-.48-.38-.758-.448-1.102a3.177 3.177 0 0 1-.052-.45l-.04.08a.25.25 0 1 1-.447-.224l.235-.468ZM6.013 2.06c-.649-.18-1.483.083-1.85.798-.131.258-.245.689-.08 1.335.063.244.414.198.487-.043.21-.697.627-1.447 1.359-1.692.217-.073.304-.337.084-.398Z"></path>
                                        </svg>{' '}
                                        <span style={{ letterSpacing: "0.2em" }}>YOUR WISHLISTS</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        : <></>
                }
                {
                    wishlists ?
                        wishlists
                        .filter(wishlist => !wishlist.archived)
                        .map(item => {
                            return <WishlistHeader key={item.wishlistId} id={item.wishlistId} onDelete={handleDeletion} isDeleting={isDeleteLoading} deleteError={deleteError} />
                        })
                        : <></>
                }
                {
                    wishlists ?
                        <Row className="justify-content-center gy-5 mb-4">
                            <Col sm={12} md={8} lg={8}>
                                <div className="m-2" style={{ textAlign: "center" }}>
                                    <Button onClick={handleNew} className="home-new">
                                        {
                                            isNewLoading ? <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            /> : <></>
                                        }
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-database-add" viewBox="0 0 16 16">
                                            <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Z"></path>
                                            <path d="M12.096 6.223A4.92 4.92 0 0 0 13 5.698V7c0 .289-.213.654-.753 1.007a4.493 4.493 0 0 1 1.753.25V4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.525 4.525 0 0 1-.813-.927C8.5 14.992 8.252 15 8 15c-1.464 0-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13h.027a4.552 4.552 0 0 1 0-1H8c-1.464 0-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10c.262 0 .52-.008.774-.024a4.525 4.525 0 0 1 1.102-1.132C9.298 8.944 8.666 9 8 9c-1.464 0-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777ZM3 4c0-.374.356-.875 1.318-1.313C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4Z"></path>
                                        </svg>{' '}
                                        NEW
                                    </Button>
                                </div>
                                <div style={{ textAlign: "center", color: "red" }}>{newError}</div>
                            </Col>
                        </Row>
                        : <></>
                }
            </div>
        </Container>
    );
}

export default Home;