import React, { useEffect, useRef, useState } from 'react';
import { Spinner, Button, Modal, Form, ListGroup, FormGroup } from 'react-bootstrap';
import { WishlistItem } from './Interfaces';
import { get_web_page_meta_tags, save_external_image, upload_file } from './MyWisher'

function cloneItem(item: WishlistItem): WishlistItem {
    return JSON.parse(JSON.stringify(item));
}

interface WishlistPopUpParams {
    wishlistId: string;
    item: WishlistItem | null;
    show: boolean;
    onClose: () => void;
    onItemUpdate: (item: WishlistItem) => void | undefined;
    position: number;
    numItems: number;
    minPriority: number;
    maxPriority: number;
}

const generateBlankItem = (priority: number): WishlistItem => {
    const d = new Date().toISOString()
    return {
        id: "",
        name: "",
        link: "",
        image: "",
        price: "",
        text: "",
        priority: priority,
        visible: true,
        archived: null,
        created: d,
        reserved: null,
        updated: d,
    };
}

enum PopupType {
    New,
    Existing
}

const WishlistPopUp = (props: WishlistPopUpParams) => {

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>("")
    const type = props.item !== null ? PopupType.Existing : PopupType.New
    const referenceItem: WishlistItem = type === PopupType.Existing ? props.item! : generateBlankItem(props.position)
    const [draftItem, setDraftItem] = useState<WishlistItem>(cloneItem(referenceItem))
    const placeHolderWishImage = '/pic/gift-1.png'

    const [browseImageToggle, setBrowseImageToggle] = useState<boolean>(false)

    const handleCancel = () => {
        props.onClose();
        setDraftItem(cloneItem(referenceItem));
    };

    const handleSave = () => {
        setLoading(true);
        setError("");

        try {
            props.onItemUpdate(draftItem);
            if (type === PopupType.New) {
                setDraftItem(cloneItem(referenceItem));
            }
            props.onClose();
        } catch (error) {
            console.error(error);
            setError("Unexpected error");
        } finally {
            setLoading(false);
        }
    }

    const handleUpdate = (e: any) => {
        const newValue = e.target.value;
        const fieldName = e.target.name;
        setDraftItem((item) => {
            let newItem = { ...item }
            switch (fieldName) {
                case 'name':
                    newItem.name = newValue;
                    break;
                case 'priority':
                    const digits = newValue.replace(/\D/g, '');
                    if (digits) {
                        newItem.priority = Number(digits);
                    } else {
                        newItem.priority = 0;
                    }
                    break;
                case 'price':
                    newItem.price = newValue;
                    break;
                case 'link':
                    newItem.link = newValue;
                    break;
                case 'image':
                    newItem.image = newValue;
                    break;
                case 'text':
                    newItem.text = newValue;
                    break;
                default:
                    console.warn("Unknown field", fieldName);
            }
            return newItem;
        });
    };

    const handleFlipVisible = (e: any) => {
        setDraftItem((item) => {
            let newItem = { ...item }
            newItem.visible = !newItem.visible
            return newItem
        });
    };

    const adjustPriority = (offset: number): void => {
        setDraftItem((old) => {
            let newItem = { ...old }
            newItem.priority = old.priority + (Number(offset) || 0);
            return newItem;
        });
    };

    const [urlError, setUrlError] = useState("");
    const [urlLoading, setUrlLoading] = useState(false);
    const [urlStr, setUrlStr] = useState("");

    const [imageUrlLoading, setImageUrlLoading] = useState(false);
    const [imageUploadError, setImageUploadError] = useState("");

    const handleSearchLink = (e: any): void => {
        const newValue = e.target.value;
        const urlText = newValue;
        setUrlStr(urlText);
    };

    const updateImage = (imageUrl: string) => {
        if (imageUrl) {
            const urlAsObj = new URL(imageUrl);
            const validProtocol = urlAsObj.protocol === 'http:' || urlAsObj.protocol === 'https:';
            if (validProtocol) {
                // save image to preview
                setImageUrlLoading(true);
                save_external_image(imageUrl).then(response => {
                    if (response.status === 201) {
                        return response.json();
                    }
                    throw response;
                }).then(data => {
                    let awsUrl = data['url'];
                    setDraftItem((old2) => {
                        let newItem = { ...old2 };
                        newItem.image = awsUrl;
                        return newItem;
                    });
                }).catch(error => {
                    console.error(error);
                    setUrlError("Failed, try again later..");
                }).finally(() => {
                    setImageUrlLoading(false);
                });
            }
        }
    }

    // calls API when user has stopped typing (750ms break) after changing URL
    useEffect(() => {
        const timer = setTimeout(() => {
            const urlText = urlStr;
            setUrlError("");

            if (urlText.trim() === "") {
                return;
            }

            try {
                const urlAsObj = new URL(urlText);
                const validProtocol = urlAsObj.protocol === 'http:' || urlAsObj.protocol === 'https:';
                if (validProtocol) {
                    setUrlLoading(true);
                    get_web_page_meta_tags(urlText).then(response => {
                        if (response.status === 200) {
                            return response.json();
                        }
                        throw response;
                    }).then(data => {
                        setDraftItem((old) => {
                            let newItem = { ...old };
                            newItem.name = data['title'] || "";
                            newItem.link = data['url'] || urlText;
                            let imageUrl = data['image'] || "";
                            updateImage(imageUrl);
                            //newItem.image = data['image'] || "";
                            return newItem;
                        });
                    }).catch(error => {
                        console.error(error);
                        setUrlError("Failed, try again later..");
                    }).finally(() => {
                        setUrlLoading(false);
                    });
                } else {
                    setUrlError("Link must start with http:// or https://");
                }
            } catch (err) {
                setUrlError("Link is invalid, it should be something like https://www.some-domain-somewhere-jdyys.com/gift/23");
            }
        }, 750)

        return () => clearTimeout(timer)
    }, [urlStr])

    function handleImageUpload(event: any): void {
        console.log(event);
        setImageUploadError(x => "");

        if (event.target.files.length < 1) {
            console.info("No file selected");
            return;
        }
        if (event.target.files.length > 1) {
            setImageUploadError(x => "Only select one file! Try again.");
            return;
        }
        const file = event.target.files[0];
        if (!file.type.startsWith("image/")) {
            setImageUploadError(x => "Only select file of image type! Try again.");
            return;
        }

        const filename = file.name;

        upload_file(file, filename)
            .then(url => {
                updateImage(url);
                setBrowseImageToggle(false);
            });
    }

    function handleImageDeletion(event: any): void {
        setDraftItem((old2) => {
            let newItem = { ...old2 };
            newItem.image = "";
            return newItem;
        });
    }

    return (
        <>
            <Modal show={props.show} onHide={handleCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>{draftItem.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup variant="flush">
                        <ListGroup.Item>
                            <Form onSubmit={handleSave}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Find using a link..</Form.Label>
                                    <Form.Control type="text" placeholder="https://www.some.link.com" name={"link"} onChange={handleSearchLink} />
                                    <Form.Text className="text-muted">
                                        Copy-paste a link here to extract information.
                                    </Form.Text>
                                </Form.Group>
                            </Form>
                            <div className="float-start">
                                <p style={{ color: "red", fontStyle: "italic" }}>{urlError}</p>
                            </div>
                            {urlLoading ? <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> : <></>
                            }
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Form onSubmit={handleSave}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Type manually..</Form.Label>
                                    <Form.Control type="text" placeholder="Title" name={"name"} value={draftItem.name} onChange={handleUpdate} />
                                    <Form.Text className="text-muted">
                                        A very short description.
                                    </Form.Text>
                                </Form.Group>
                                <FormGroup className="mb-3">
                                    {imageUrlLoading ? <Spinner
                                        as="span"
                                        animation="border"
                                        role="status"
                                        aria-hidden="true"
                                    /> :
                                        <div style={{ position: "relative" }}>
                                            <img src={draftItem.image || placeHolderWishImage} alt="wish representation" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} className='img-thumbnail'></img>
                                            <div style={{ position: "absolute", bottom: 20, right: "50%", marginRight: "-50px" }}>
                                                <Button color="white" variant="light" className="btn btn-outline-secondary wishlist-popup-edit-image" onClick={e => setBrowseImageToggle(!browseImageToggle)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-wrench-adjustable" viewBox="0 0 16 16">
                                                        <path d="M16 4.5a4.5 4.5 0 0 1-1.703 3.526L13 5l2.959-1.11q.04.3.041.61"></path>
                                                        <path d="M11.5 9c.653 0 1.273-.139 1.833-.39L12 5.5 11 3l3.826-1.53A4.5 4.5 0 0 0 7.29 6.092l-6.116 5.096a2.583 2.583 0 1 0 3.638 3.638L9.908 8.71A4.5 4.5 0 0 0 11.5 9m-1.292-4.361-.596.893.809-.27a.25.25 0 0 1 .287.377l-.596.893.809-.27.158.475-1.5.5a.25.25 0 0 1-.287-.376l.596-.893-.809.27a.25.25 0 0 1-.287-.377l.596-.893-.809.27-.158-.475 1.5-.5a.25.25 0 0 1 .287.376M3 14a1 1 0 1 1 0-2 1 1 0 0 1 0 2"></path>
                                                    </svg>
                                                </Button>
                                                {" "}
                                                <Button color="white" variant="light" className="btn btn-outline-secondary wishlist-popup-delete-image" onClick={handleImageDeletion}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                                    </svg>{' '}
                                                </Button>

                                            </div>
                                        </div>
                                    }
                                    {(browseImageToggle && <Form.Control type="file" onChange={handleImageUpload} />)}
                                    <>
                                        {(imageUploadError && <p style={{ color: "red" }}>{imageUploadError}</p>)}
                                    </>
                                    <br />

                                    {" "}

                                </FormGroup>
                                <Form.Group className="mb-3">
                                    <Form.Control type="text" placeholder="Price" name={"price"} value={draftItem.price} onChange={handleUpdate} />
                                    <Form.Text className="text-muted">
                                        Approximate purchase price.
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Control type="text" placeholder="Link" name={"link"} value={draftItem.link} onChange={handleUpdate} />
                                    <Form.Text className="text-muted">
                                        An URL link to the wish.
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Control as="textarea" placeholder="Description" name={"text"} rows={3} value={draftItem.text} onChange={handleUpdate} />
                                    <Form.Text className="text-muted">
                                        A description of the wish.
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Check type="checkbox" label="Temporarily hide from visitors" name={"hidden"} checked={draftItem.visible === false} onChange={handleFlipVisible} />
                                </Form.Group>
                            </Form>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Label>Priority</Form.Label>
                                    <Form.Control type="number" placeholder="Priority" name={"priority"} value={draftItem.priority} onChange={handleUpdate} maxLength={3} />
                                    <Form.Text className="text-muted">
                                        Position {props.position} of {props.numItems} items
                                    </Form.Text>
                                    <br />
                                    <Button variant="outline-secondary" onClick={e => adjustPriority(-1)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                                        </svg>
                                    </Button>
                                    {" "}
                                    <Button variant="outline-secondary" onClick={e => adjustPriority(1)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </Button>
                                    {" "}
                                    <Button variant="outline-secondary" onClick={e => adjustPriority(-5)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-up" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M7.646 2.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 3.707 2.354 9.354a.5.5 0 1 1-.708-.708l6-6z"></path>
                                            <path fillRule="evenodd" d="M7.646 6.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 7.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"></path>
                                        </svg>
                                    </Button>
                                    {" "}
                                    <Button variant="outline-secondary" onClick={e => adjustPriority(5)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-down" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                            <path fillRule="evenodd" d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </Button>
                                    {" "}
                                    <Button variant="outline-secondary" onClick={e => adjustPriority(props.minPriority - 1 - draftItem.priority)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-bar-up" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M3.646 11.854a.5.5 0 0 0 .708 0L8 8.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708zM2.4 5.2c0 .22.18.4.4.4h10.4a.4.4 0 0 0 0-.8H2.8a.4.4 0 0 0-.4.4z" />
                                        </svg>
                                    </Button>
                                    {" "}
                                    <Button variant="outline-secondary" onClick={e => adjustPriority(props.maxPriority + 1 - draftItem.priority)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-bar-down" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M3.646 4.146a.5.5 0 0 1 .708 0L8 7.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zM1 11.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z" />
                                        </svg>
                                    </Button>
                                    {" "}
                                </Form.Group>
                            </Form>
                        </ListGroup.Item>
                    </ListGroup>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{ width: "100%" }}>
                        <div className="float-start">
                            <p style={{ color: "red", fontStyle: "italic" }}>{error}</p>
                        </div>
                        <div className="float-end">
                            <Button variant="secondary" onClick={!loading ? handleCancel : undefined} disabled={loading} className="wishlist-popup-cancel">
                                Close
                            </Button>
                            {' '}
                            <Button variant="primary" onClick={!loading ? handleSave : undefined} disabled={loading} className="wishlist-popup-save">
                                {loading ? <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> : <></>
                                }
                                Save Changes
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default WishlistPopUp;